import React from "react";

const TermsAndConditions = () => (
  <section>
    <div className="container py-16">
      {/* <h1 className="font-bold text-6xl mb-[100px]">PRIVACY POLICY</h1> */}
      <h1 className="font-bold mb-3 text-[52px]">{"COOKIE POLICY"}</h1>
      <p className="mb-6 text-[17px]">
        This Cookies Policy describes the different types of cookies that ("Digital Research", "we",
        "us" or "our") uses on its website (https://readmedigital.com/) and the choices that users
        have. Digital Research ("we", "us" or "our") wants to ensure that your visit to our website
        is smooth, trusty and as useful to you as possible. For this reason, we want to use cookies
        to make our website relevant to your interests and needs. When you first visit our website,
        you will be asked to consent to the use of cookies in accordance with this Cookies Policy.
        Note that if you accept, we will continue to store them on your device.
      </p>
      <h2 className="font-bold mb-3 text-[42px]">What are cookies?</h2>
      <p className="mb-6 text-[17px]">
        When you visit our website, information is stored on your device in the form of a "cookie."
        Cookies are small files that are stored on your device and save certain settings and data to
        exchange with our websites via your browser.
        <br />
        <br />
        As a general rule, we never collect personal data via cookies, unless you have given us your
        express permission to do so.
        <br />
        <br />
        If you do not want us to recognize your device, please configure your browser to erase all
        cookies from your device, to block all cookies or to receive a warning before a cookie is
        stored. You will find brief instructions on how to do this below.
        <br />
        <br />
        Please note that certain functions of our website may no longer work, or not correctly,
        without cookies.
      </p>
      <h2 className="font-bold mb-3 text-[42px]">What types of cookies do we use?</h2>
      <h3 className="font-bold mb-3 text-gray-500 text-[36px]">Strictly necessary cookies</h3>
      <p className="mb-6 text-[17px]">
        These cookies are necessary for the website to function and cannot be switched off in our
        systems. They are usually only set in response to actions made by you which amount to a
        request for services, such as setting your privacy preferences, logging in or filling in
        forms. You can set your browser to block or to receive a warning before these cookies are
        stored, but some parts of our website may not work properly in that case.
      </p>
      <h3 className="font-bold mb-3 text-gray-500 text-[36px]">Functional cookies</h3>
      <p className="mb-6 text-[17px]">
        These cookies enable our website to provide enhanced functionality and display customized
        videos and images. They may be set by us or by third party providers whose services we have
        added to our website. Without these cookies, some or all of these services may not function
        properly.
      </p>
      <h3 className="font-bold mb-3 text-gray-500 text-[36px]">Performance/Analytics Cookies</h3>
      <p className="mb-6 text-[17px]">
        These cookies allow us to count visits and traffic sources so we can measure and improve the
        performance of our website. They help us understand which pages are the most and least
        popular and learn how visitors move around our website. Without these cookies, we will not
        know when you have visited our website and will not be able to monitor their performance.
      </p>
      <h3 className="font-bold mb-3 text-gray-500 text-[36px]">Targeting Cookies</h3>
      <p className="mb-6 text-[17px]">
        These cookies may be set through our website by our advertising partners. They may be used
        by those companies to build a profile of your interests and to show adverts that are
        relevant to you. They are based on uniquely identifying your browser and your device.
        Without these cookies, you will experience less targeted advertising.
      </p>
      <h2 className="font-bold mb-3 text-[42px]">How to control cookies</h2>
      <p className="mb-6 text-[17px]">
        Most browsers are set to accept cookies by default. If you prefer, you can set your browser
        to disable cookies or to notify you when cookies are being stored. If you block cookies or
        refuse to accept our website’s request to place cookies, some parts of our website will not
        function properly or potentially not work at all.
        <br />
        <br />
        You can find more information about cookie management on the web-browser help pages by
        visiting the following links:
        <br />
        <br />
        <ul className="pl-8 list-disc list-inside">
          <li>
            <a
              className="text-[#2962FF]"
              href="https://support.google.com/chrome/answer/95647"
              target="_blank"
              rel="noreferrer"
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              className="text-[#2962FF]"
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
              target="_blank"
              rel="noreferrer"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              className="text-[#2962FF]"
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
              target="_blank"
              rel="noreferrer"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              className="text-[#2962FF]"
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
              target="_blank"
              rel="noreferrer"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              className="text-[#2962FF]"
              href="https://support.microsoft.com/en-us/help/4027947/windows-delete-cookies"
              target="_blank"
              rel="noreferrer"
            >
              Microsoft Edge
            </a>
          </li>
          <li>
            <a
              className="text-[#2962FF]"
              href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/11.0/mac/10.13"
              target="_blank"
              rel="noreferrer"
            >
              Safari ( macOS )
            </a>
          </li>
          <li>
            <a
              className="text-[#2962FF]"
              href="https://support.apple.com/en-us/HT201265"
              target="_blank"
              rel="noreferrer"
            >
              Safari ( iOS )
            </a>
          </li>
        </ul>
        <br />
        For other browsers, please consult the documentation that your browser manufacturer
        provides.
      </p>
    </div>
  </section>
);

export default TermsAndConditions;
